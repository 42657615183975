<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "HC",

  metaInfo: { title: "Nilai Perusahaan" },

  extends: View,

  mixins: [LoadSections(["hero", "core-values", "info"])],

  props: {
    id: {
      type: String,
      default: "hc",
    },
  },
};
</script>
